import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  body {
    font-family: ${({ theme }) => theme.fonts.family.nunito};
  }
  div, nav, input, textarea {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }
  .grid {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
  }
  .flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const theme = {
  fonts: {
    family: {
      vollkorn: "'Vollkorn', sans-serif",
      nunito: "'Nunito', sans-serif"
    }
  },
  colors: {
    primary: '#00c1bb',
    primarydark: '#0a2126',
    pureblack: '#000000',
    black: '#0c0f16',
    white: '#ffffff',
    grey1: '#999999',
    grey2: '#bfbfbf',
    grey3: '#666666',
    green1: '#065556',
  }
};

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}